.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    width: 100%;
  }
  
  .header__icon {
    object-fit: contain;
    height: 100px;
    margin-left: 20px;
  }
  
  .header__center {
    display: flex;
    flex: 1;
    align-items: center;
    max-width: fit-content;
    padding: 10px;
    height: 30px;
    border: 1px solid lightgray;
    border-radius: 999px;
  }
  
  .header__center > input {
    border: none;
    padding: 10px;
    outline-width: 0;
    /* width: 250px; */
  }
  
  .header__right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 15vw;
    margin-right: 80px;
  }